import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <h2>Demandez un devis</h2>
          <p>Besoin de l’avis d’un Expert construction ? Expliquez nous votre problématique, nous vous répondrons sous 48 heures.</p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form id="contactForm" method="post" action="https://formulaire.koudepouce.fr/rvexpertises/form1/">
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="name" className="form-control" required placeholder="Votre nom" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input type="email" name="email" className="form-control" required placeholder="Votre email" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input type="text" name="phone_number" className="form-control" required placeholder="Votre téléphone" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input type="text" name="localisation" className="form-control" required placeholder="Votre localisation" />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Message..." />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <p>
                        <input type="checkbox" id="cgu" name="cgu" className="form-check" required /> J'accepte les <a href="/legal/cgu/" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>*
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="default-btn">
                      <i className="flaticon-tick"></i>
                      Envoyer <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
